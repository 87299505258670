import { Stack, Menu, MenuItem, IconButton, styled } from "@mui/material";
import { Link } from "react-router-dom";
import { useState } from "react";
import { logo } from "../utils/constants";
import { SearchBar } from "./";
import { Telegram, Twitter, Description, Timeline, Menu as MenuIcon, EventNote } from "@mui/icons-material";

const AnimatedIconButton = styled(IconButton)(({ theme }) => ({
  color: 'red',
  transition: 'transform 0.3s',
  transform: 'scale(1.5)',
  '&:hover': {
    transform: 'scale(1.75)',
  },
}));

const RedAnimatedIconButton = styled(IconButton)(({ theme }) => ({
  color: 'grey',
  transition: 'color 0.4s',
  '&:hover': {
    color: 'red',
  },
}));

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const preSaleLink = "https://docs.google.com/forms/d/e/1FAIpQLSc5j0Q4dii-D7XNW0pwSSmKqR7dN14uPIT-CFCaCJbhKOkKfQ/viewform?usp=sf_link";

  return (
    <Stack
      direction="row"
      alignItems="center"
      p={2}
      sx={{
        position: "sticky",
        background: "#000",
        top: 0,
        justifyContent: "space-between",
      }}
    >
      <Link to="/" style={{ display: "flex", alignItems: "center" }}>
        <img src={logo} alt="logo" height={45} />
      </Link>
      <Stack direction="row" alignItems="center">
        <SearchBar />
        <AnimatedIconButton
          onClick={handleMenuOpen}
          color="inherit"
          aria-controls="menu"
          aria-haspopup="true"
        >
          <MenuIcon />
        </AnimatedIconButton>
        <Menu
          id="menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleMenuClose}>
            <RedAnimatedIconButton
              component="a"
              href="https://t.me/CrypTubeToken"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Telegram />
            </RedAnimatedIconButton>
            <a href="https://t.me/CrypTubeToken" target="_blank" rel="noopener noreferrer">
              Telegram
            </a>
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <RedAnimatedIconButton
              component="a"
              href="https://twitter.com/CrypTubeBSC"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Twitter />
            </RedAnimatedIconButton>
            <a href="https://twitter.com/CrypTubeBSC" target="_blank" rel="noopener noreferrer">
              Twitter
            </a>
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <RedAnimatedIconButton
              component="a"
              href="https://cryptube.gitbook.io/cryptube-whitepaper/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Description />
            </RedAnimatedIconButton>
            <a href="https://cryptube.gitbook.io/cryptube-whitepaper/" target="_blank" rel="noopener noreferrer">
              Whitepaper
            </a>
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <RedAnimatedIconButton
              component="a"
              href="https://cryptube.gitbook.io/cryptube-whitepaper/platform-overview/roadmap"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Timeline />
            </RedAnimatedIconButton>
            <a href="https://cryptube.gitbook.io/cryptube-whitepaper/platform-overview/roadmap" target="_blank" rel="noopener noreferrer">
              Roadmap
            </a>
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <RedAnimatedIconButton
              component="a"
              href={preSaleLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <EventNote />
            </RedAnimatedIconButton>
            <a href={preSaleLink} target="_blank" rel="noopener noreferrer">
              Pre-Sale
            </a>
          </MenuItem>
        </Menu>
      </Stack>
    </Stack>
  );
};

export default Navbar;
